import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import Prefix from '@asteria/component-prefix';

import { Translation } from '@asteria/language';

import { getDateRange } from '../../../utils';

import { DataPropTypes } from './constants';

/**
 * @typedef { import('./types').DataProp } DataProp
 */

/** @type { React.FC<{ data: DataProp, startDate: string, endDate: string, label: string }> } */
const TooltipView = React.memo(function TooltipView(props) {
	const { data, label, startDate, endDate } = props;

	const paid = data?.PAID?.total ?? 0;
	const forecast = data?.FORECAST?.total ?? 0;

	const dateRange = getDateRange({ startDate, endDate });

	let amount = paid;

	if (dateRange === 'future') {
		amount = forecast;
	}

	const translationData = React.useMemo(
		() => ({ data, paid, forecast, amount, label, startDate, endDate }),
		[amount, label, data, forecast, paid, startDate, endDate],
	);

	if (!data) {
		return null;
	}

	return (
		<div className="flex flex-col gap-4 bg-tooltip-normal-background w-80 p-4 shadow items-center justify-center rounded">
			<div className="flex flex-col items-center justify-center gap-1">
				<Translation
					size="sm"
					className="asteria--type-title"
					translationKey={[
						'graph.account.title',
						`graph.account.title.${dateRange}`,
						'card.variant-incoming.graph.account.title',
						`card.variant-incoming.graph.account.title.${dateRange}`,
					]}
					Component={Text}
					data={translationData}
				/>
				<Translation
					size="sm"
					defaultText="{{ amount | number }}"
					Component={Text}
					data={translationData}
				/>
			</div>
			<div className="flex w-full flex-col gap-2">
				{paid ? (
					<div className="asteria-component__chart-tooltip-line">
						<div className="asteria-component__chart-tooltip-label">
							<Prefix colors={['account', 'paid']} />
							<Translation
								size="sm"
								translationKey="graph.account.legend.history"
								Component={Text}
								data={translationData}
							/>
						</div>
						<Translation
							size="sm"
							defaultText="{{ paid | number }}"
							className="text-right"
							Component={Text}
							data={translationData}
						/>
					</div>
				) : null}
				{forecast ? (
					<div className="asteria-component__chart-tooltip-line">
						<div className="asteria-component__chart-tooltip-label">
							<Prefix colors={['account', 'forecast']} />
							<Translation
								size="sm"
								translationKey="graph.account.legend.forecast"
								Component={Text}
								data={translationData}
							/>
						</div>
						<Translation
							size="sm"
							defaultText="{{ forecast | number }}"
							className="text-right"
							Component={Text}
							data={translationData}
						/>
					</div>
				) : null}
				{data?.CREDIT?.total ? (
					<div className="asteria-component__chart-tooltip-line">
						<div className="asteria-component__chart-tooltip-label">
							<Prefix colors="credit" />
							<Translation
								size="sm"
								translationKey="graph.account.legend.credit"
								Component={Text}
								data={translationData}
							/>
						</div>
						<Translation
							size="sm"
							defaultText="{{ data.CREDIT.total | number }}"
							className="text-right"
							Component={Text}
							data={translationData}
						/>
					</div>
				) : null}
			</div>
		</div>
	);
});

TooltipView.propTypes = {
	data: PropTypes.shape(DataPropTypes),
	label: PropTypes.string,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
};

export default TooltipView;
