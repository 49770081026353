import React from 'react';

import PropTypes from 'prop-types';

import { DataPropTypes } from './constants';
import TooltipView from './tooltip.view';

/**
 * @typedef { import('./types').DataProp } DataProp
 */

/** @type { React.FC<{ payload: { payload: { data: DataProp } }[] }> } */
const TooltipProvider = React.memo(function TooltipProvider(props) {
	const { payload } = props;

	const response = React.useMemo(() => {
		const value = payload.find((object) => object?.payload?.data);

		return value?.payload;
	}, [payload]);

	return (
		<TooltipView
			data={response?.data}
			startDate={response?.dates?.startDate}
			endDate={response?.dates?.endDate}
			label={response?.label}
		/>
	);
});

TooltipProvider.propTypes = {
	payload: PropTypes.arrayOf(PropTypes.shape(DataPropTypes)),
};

export default TooltipProvider;
