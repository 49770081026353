import React from 'react';

import PropTypes from 'prop-types';
import {
	Area,
	Legend as BaseLegend,
	Tooltip as BaseTooltip,
	CartesianGrid,
	ComposedChart,
	Customized,
	Line,
	ReferenceLine,
	ResponsiveContainer,
	XAxis,
	YAxis,
} from 'recharts';

import { useMediaQuery } from '@asteria/component-tools/mediaquery';

import formatNumber from '@asteria/utils-funcs/formatNumber';

import Legend from './legend';
import SpreadDefs from './spread-defs';
import Tooltip from './tooltip';

/**
 * @typedef { import('../../../basic/types').Props } Props
 * @typedef { import('../../../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<{ points: unknown[] }> } */
const ChartView = React.memo(function ChartView(props) {
	const { points } = props;

	const isTablet = useMediaQuery('(max-width: 640px)');

	return (
		<div className="h-60">
			<ResponsiveContainer width="100%" height="100%">
				<ComposedChart
					data={points}
					margin={{ top: 8, right: 32, left: 32, bottom: 8 }}
				>
					<Customized
						component={
							<SpreadDefs className="asteria-component__graph-spread asteria--variant-outer" />
						}
					/>
					<YAxis
						padding={{ top: 0, bottom: 0 }}
						tickFormatter={(value) =>
							formatNumber(value, false, false, false, {
								useReduction: true,
							})
						}
						width={60}
						axisLine={false}
						className="text-xs truncate text-graph-yaxis-part-hover-text"
						tickLine={false}
						hide={isTablet}
					/>
					<XAxis
						className="text-xs truncate text-graph-yaxis-part-hover-text"
						tickMargin={12}
						dataKey="label"
						axisLine={false}
						tickLine={false}
					/>
					<CartesianGrid
						stroke="var(--color-graph-yaxis-part-line-normal-background,var(--color-graph-part-line-background,#cfcfcf))"
						strokeWidth={0.5}
						vertical={false}
					/>

					<ReferenceLine
						y={0}
						stroke="var(--color-graph-yaxis-part-line-normal-zero, var(--color-graph-part-line-zero, var(--color-zero-normal, var(--color-zero, rgba(207, 207, 207, 1)))))"
						// strokeWidth={2}
					/>
					<Area
						type="monotone"
						dataKey="area"
						fill="url(#spread-normal-pattern)"
						filter="url(#spread-blur)"
						stroke="none"
					/>
					<Line
						type="basis"
						dataKey="credit"
						stroke="var(--color-graph-line-normal-credit, var(--color-graph-credit, var(--color-credit-normal, var(--color-credit, rgba(0, 0, 0, 1)))))"
						strokeWidth={2}
						dot={false}
					/>
					<Line
						type="natural"
						dataKey="value"
						stroke="var(--color-graph-line-normal-background, var(--color-graph-background, rgba(0, 0, 0, 1)))"
						strokeWidth={2}
						dot={{ strokeDasharray: 0, r: 5 }}
					/>

					<Line
						type="natural"
						dataKey="forecast"
						stroke="var(--color-graph-line-normal-forecast, var(--color-graph-forecast, var(--color-forecast-normal, var(--color-forecast, rgba(0, 0, 0, 1)))))"
						strokeWidth={2}
						strokeDasharray={8}
						dot={{ strokeDasharray: 0, r: 5 }}
					/>

					<BaseTooltip content={<Tooltip />} cursor={false} />
					<BaseLegend content={<Legend points={points} />} />
				</ComposedChart>
			</ResponsiveContainer>
		</div>
	);
});

ChartView.propTypes = {
	points: PropTypes.arrayOf(PropTypes.object),
};

export default ChartView;
